


































































































@import '@design';

.home-hero {
  position: relative;
}

.v-card.hero-cta {
  background-color: transparent;
}
